const idPortalApi = "365007aa304fbeeeb7ec6a3c6b97c4d8ec84cb6a";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.saofelixdebalsas.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "saofelixdebalsas.ma.gov.br",
  NomePortal: "São Felix de Balsas",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 8, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#000080",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/Bd3JM93JYpcyrWQT9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d474.83577719215117!2d-44.814666022214745!3d-7.085432392641529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78063829319a53f%3A0x83dc52b7abaca15d!2zU8OjbyBGw6lsaXggZGUgQmFsc2FzIC0gTWFyYW5ow6Nv!5e1!3m2!1spt-BR!2sbr!4v1713982915379!5m2!1spt-BR!2sbr`,
};
